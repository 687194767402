import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "The Veil of Ignorance",
  "date": "2016-03-25",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Adam Morse on accessibility:`}</p>
    <blockquote>
      <p parentName="blockquote">{`When I sit down to design things I try to put on the veil of ignorance. I imagine a world where I am not who I am right now. And I think about all the things that could possibly frustrate me. Then I think some more. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`I try to design for that reality. I don’t design for myself and my perfect eyesight, my retina screens, and my fast internet connection. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`I design for everyone I can think of. Which is a growing list of people with a growing list of concerns that possess a growing number of devices made up of a wide variety of screen sizes.`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "http://mrmrs.io/writing/2016/03/23/the-veil-of-ignorance/"
      }}>{`Read the full piece`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      